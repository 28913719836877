.image {
  height: 100%;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
}

.image-0 {
}

.image-20 {
  mix-blend-mode: screen;
}
.image-21 {
  mix-blend-mode: screen;
}

.isolate {
  isolation: isolate; /* Without isolation, the background color will be taken into account */
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 540px;
  max-width: 540px;
}
