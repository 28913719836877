.dropdown-menu {
  /* display: none; */
  position: absolute;
  background-color:rgb(14, 13, 13);
  min-width: 200px; /* Set the width of the dropdown menu */
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;
}

.dropdown-menu a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  /* background-color: #f1f1f1; */
  color: rgb(255, 182, 49)
}

.show {
  display: block;
}