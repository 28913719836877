.item-enter {
  opacity: 0;
  transform: scale(0.9);
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
