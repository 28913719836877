@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");
/* @font-face {
  font-family: "PPNeueMachina";
  src: local("PPNeueMachina"),
    url(../assets/fonts/PPNeueMachina-Ultrabold.woff) format("woff");
} */

@font-face {
  font-family: "PPNeueMachina";
  src: local("PPNeueMachina"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-Bold.otf) format("woff");
}
@font-face {
  font-family: "EuroStileBoldObq";
  src: local("EuroStileBoldObq"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-BoldOblique.otf)
      format("woff");
}
@font-face {
  font-family: "EuroStilMedium";
  src: local("EuroStilMedium"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd.otf) format("woff");
}
@font-face {
  font-family: "EurostilDemi";
  src: local("EurostilDemi"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-Demi\ 2.otf) format("woff");
}

@font-face {
  font-family: "EurostilCond";
  src: local("EurostilCond"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-Cn.otf) format("woff");
}
@font-face {
  font-family: "EurostilObq";
  src: local("EurostilObq"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-Oblique.otf) format("woff");
}
@font-face {
  font-family: "EurostilNormal";
  src: local("EurostilNormal"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd\ 2.otf) format("woff");
}
@font-face {
  font-family: "EurostilExtended";
  src: local("EurostilExtended"),
    url(../assets/fonts//EUROSTILE/EurostileLTStd-BoldEx2.otf) format("woff");
}
