body {

    height:100%
}

.videoContainer {
  position: relative;
}

.videoContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
